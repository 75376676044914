html {
    color: #ffffff75
}

h2 {
    color: #ffffffcc;
}

a {
    text-decoration: none;
    color: #6cabeb;
}

a:hover {
    text-decoration: underline;
    color: #6cabeb;
}

.home {
    text-align: left;
    background-color: #101f2e;
    padding: 12vw;
    color: #6cabeb;
    font-family: Consolas, Monaco, monospace;
}

.carousel-img {
    max-height: 650px;
}

.caption {
    position: absolute;
    top: 50%;
}

.title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.title-bio {
    color: #ffffff75;
    max-width: 50%;
    padding-top: 1.5vw;
}

.info {
    color: #ffffff75;
    padding-top: 1.5vw;
}

.section {
    padding-top: 20vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 15vh;
    border-bottom: 1px solid #ffffffcc;
}

.box {
    box-shadow: 30px 30px 15px #0d1822;
}

.card {
    background-color: #101f2e;
    border-color: #ffffffcc;
    border-width: 0.01rem;
    margin: 1rem 0;
    padding: 0.5rem;
    color: #ffffff75;
    /* min-width: 50%; */
    width: 100%;
    /* display: inline-block; */
}

.list-item {
    background-color: #101f2e;
    color: #ffffff75;
    padding: 0 0.25rem;
    border-color: #09131d;
    /* border-width: 1rem; */
}

.icon {
    width: 1.5rem;
}

.popout {
    transition: transform 2s;
}

.popout:hover {
    box-shadow: 0 0 3rem #0a1520;
    transform: scale(1.025);
}

.home h4 {
    font-size: 1.25rem;
}

.title h1 {
    font-size: 4.5vw;
}

.title h2 {
    font-weight: bold;
}

.info h4,
.card h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.info h5,
.card h5 {
    font-size: 1.25rem;
    color: #ffffffcc;
    font-style: italic;
}

#name {
    color: #ffffffcc;
    font-weight: bolder;
}

#subname {
    color: #ffffffa1;
    font-weight: bolder;
}

#exp-card {
    padding: 1.75rem 1.5rem;
}

#about .title,
#projects .title,
#experience .title,
#contact .title {
    text-align: center;
    margin: auto;
    width: 50%;
    padding-bottom: 1.25rem;
}

#img-container {
    margin: 0 3rem;
    max-width: 25vw;
}

#about-info {
    text-align: left;
    padding: 1.65rem;
    margin: 1.5rem;
    /* transition: transform 2s; */
}

/* #about-info:hover {
    box-shadow: 0 0 3rem #0a1520;
    transform: scale(1.025);
} */

#container {
    display: flex;
    align-items: center;
    justify-content: center
}

#home {
    padding-bottom: 15vh;
    border-bottom: 1px solid #ffffffcc;
}

#name-icon {
    width: 2.25rem;
}

#last-icon {
    padding-right: 1.5rem;
}

#penultimate-icon {
    padding-right: 65rem;
}